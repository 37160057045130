import { Box, Flex, Icon, IconButton, Input, Progress, Td, Text, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaFileDownload } from "react-icons/fa";
import { PersonDto } from "../../../autogen/bff-api";
import { ProfileImageRounded } from "../../../pages/sourcing-events/buyer/view-all/ProfileImageRounded";
import { displayDate } from "../../formatting/displayDate";
import { DownloadDocumentIconButton } from "../DownloadDocumentIconButton/DownloadDocumentIconButton";
import { RemoveFileButton } from "./RemoveFileButton";

interface Props {
  file: FileUpload;
  name: string;
  remove: (file: FileUpload) => Promise<void>;
  onUpdateName?: (name: string) => Promise<void>;
}

export type FileUpload =
  | {
      status: "UploadingToBrowser";
      name: string;
      uploadId: string;
    }
  | {
      status: "UploadingToServer";
      name: string;
      uploadId: string;
    }
  | {
      status: "UploadFailed";
      name: string;
      uploadId: string;
    }
  | {
      status: "UploadCompleted";
      name: string;
      documentId: string;
      uploadId: string;
      uploadedBy: PersonDto;
      uploadedAt: string;
    };

export const FileRow = ({ file, name, remove, onUpdateName }: Props) => {
  const { t } = useTranslation();
  const getProgressBar = () => {
    let text: string;
    let value: number;
    let color = "teal";
    switch (file.status) {
      case "UploadCompleted":
        return null;
      case "UploadFailed":
        text = t("Upload failed!");
        value = 0;
        color = "red";
        break;
      case "UploadingToBrowser":
        text = t("Uploading") + "...";
        value = 34;
        break;
      case "UploadingToServer":
        text = t("Scanning file") + "...";
        value = 64;
        break;
    }
    return (
      <Box py="1">
        <Progress value={value} colorScheme={color} rounded="md" />
        <Text fontWeight={"bold"} fontSize="sm" pt="1">
          {text}
        </Text>
      </Box>
    );
  };

  if (file.status !== "UploadCompleted")
    return (
      <>
        <Tr>
          <Td borderBottom="none" pb="2">
            <Text fontSize={"sm"}>{name}</Text>
          </Td>
          <Td colSpan={2} borderBottom="none" pb="2"></Td>
          <Td borderBottom="none" pb="2">
            <IconButton aria-label="disabled download icon" icon={<Icon as={FaFileDownload} />} isDisabled />
          </Td>
          <Td borderBottom="none" pb="2">
            <RemoveFileButton
              confirmRemoval={async () => {
                await remove(file);
              }}
            />
          </Td>
        </Tr>
        <Tr>
          <Td borderTop="none" colSpan={5} pt="0">
            {getProgressBar()}
          </Td>
        </Tr>
      </>
    );

  return (
    <Tr>
      <Td>
        <Input
          size="sm"
          rounded="md"
          fontWeight={"normal"}
          value={name}
          _focus={{
            borderColor: onUpdateName ? "default" : "inherit",
            boxShadow: onUpdateName ? "default" : "inherit",
          }}
          _disabled={{ color: "smMuted", border: "none" }}
          isDisabled={file.status !== "UploadCompleted" || !onUpdateName}
          onChange={(e) => onUpdateName?.(e.target.value)}
        />
      </Td>
      <Td>
        {file.status === "UploadCompleted" && (
          <Flex alignItems="center">
            <Flex>
              <ProfileImageRounded
                name={file.uploadedBy.firstName}
                backgroundColor={"smPrimary"}
                width={"5"}
                fontSize="xx-small"
              />
            </Flex>
            <Flex pl="1">
              <Text fontSize={"sm"}>{`${file.uploadedBy.firstName} ${file.uploadedBy.lastName}`}</Text>
            </Flex>
          </Flex>
        )}
      </Td>
      <Td>{file.status === "UploadCompleted" && <Text fontSize={"sm"}>{displayDate(file.uploadedAt)}</Text>}</Td>
      <Td>{file.status === "UploadCompleted" && <DownloadDocumentIconButton documentId={file.documentId} />}</Td>
      <Td>
        <RemoveFileButton
          confirmRemoval={async () => {
            await remove(file);
          }}
        />
      </Td>
    </Tr>
  );
};
