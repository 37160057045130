import { Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useListContractsQuery } from "../../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../../common/auth/useLoggedInWithOrgContextState";
import { MultiSelector } from "../../../../../../common/input/Selector/MultiSelector";
import { SelectorValue } from "../../../../../../common/input/Selector/SelectorValue";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const LinkedContacts = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const contractState = useContractState();
  const [isLoading, setIsLoading] = useState(false);
  const errorMessage = containsError(contractState, "EditLinkedContracts") ? t("Update failed") : null;
  const dispatch = useAppDispatch();
  const [contracts, setContracts] = useState<SelectorValue[]>([]);
  const authState = useLoggedInWithOrgContextState();
  const { data } = useListContractsQuery({
    orgId: authState.selectedOrg.id,
    state: "Published",
  });

  useEffect(() => {
    if (contractState.contract.dataFields?.linkedContracts) {
      setContracts(
        contractState.contract.dataFields.linkedContracts.map((e) => ({
          label: e.title,
          value: e.id,
        }))
      );
    }
  }, []);

  const eventHandler = async (contractIds: string[]) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditLinkedContracts",
          value: contractIds,
        },
      })
    );
    setIsLoading(false);
  };

  if (!data) {
    return <div>{t("Loading...")}</div>;
  }

  return (
    <FormControl isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
      <FormLabel htmlFor={"value"}>
        {t("Linked contracts")} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Flex w="100%">
        <Flex borderRadius={"10px"} backgroundColor="smBackground" w="100%">
          <MultiSelector
            value={contracts}
            options={data.contracts
              .filter((e) => e.id !== contractState.contract.id)
              .map((e) => ({
                label: e.title,
                value: e.id,
              }))}
            noOptionsAvailableMessage={t("No contracts available")}
            onChange={(value) => {
              setContracts(value);
              eventHandler(value.map((e) => e.value));
            }}
          />
        </Flex>
        <Flex>
          <RemoveDataFieldButton removeDataField={removeDataField} />
        </Flex>
      </Flex>

      <FormHelperText>{t("Provide linked contracts")}</FormHelperText>
      <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
