import { Box, Flex, FormControl, FormErrorMessage, Icon, IconButton, Input } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaTrashAlt } from "react-icons/fa";
import isEmail from "validator/lib/isEmail";

export interface SignerInput {
  firstName: string;
  lastName: string;
  email: string;
}

interface Props {
  id: string;
  index: number;
  remove: () => void;
  containsError: boolean;
}

export const InvitedSigner = ({ id, index, remove, containsError }: Props) => {
  const { t } = useTranslation();

  const { register } = useFormContext<{
    manualSigners: SignerInput[];
  }>();

  return (
    <FormControl isInvalid={containsError} borderRadius={"10px"}>
      <Flex minH={"40px"} alignItems="center" columnGap="2">
        <Box flex={1}>
          <Input
            key={id}
            {...register(`manualSigners.${index}.email`, { required: true, validate: isEmail })}
            placeholder="Email"
            type={"email"}
            size="sm"
            rounded="md"
          />
        </Box>
        <Box flex={1}>
          <Input
            key={id}
            {...register(`manualSigners.${index}.firstName`, { required: true, minLength: 1, maxLength: 200 })}
            placeholder={t("First name") ?? ""}
            size="sm"
            rounded="md"
          />
        </Box>
        <Box flex={1}>
          <Input
            key={id}
            {...register(`manualSigners.${index}.lastName`, {
              required: true,
              minLength: 1,
              maxLength: 200,
            })}
            placeholder={t("Last name") ?? ""}
            size="sm"
            rounded="md"
          />
        </Box>
        <Box>
          <IconButton
            aria-label="remove signer"
            variant={"solid"}
            colorScheme="red"
            size="sm"
            icon={<Icon as={FaTrashAlt} />}
            onClick={remove}
          />
        </Box>
      </Flex>

      <FormErrorMessage>{containsError && t("Please provide a valid email,first name and last name")}</FormErrorMessage>
    </FormControl>
  );
};
