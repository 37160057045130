import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectDto, useListContractTemplatesQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { Logo } from "../../../common/Logo";
import { useSub } from "../../../common/subscription/useSub";
import { CreateContractForm } from "./CreateContractForm";
import { CreateContractWithAIForm } from "./CreateContractWithAIForm";

interface Props {
  project?: ProjectDto;
  onClose: () => void;
  goToContractOnCreation?: boolean;
}

type FormType = "manual" | "ai";

export const CreateContractModal = ({ project, onClose, goToContractOnCreation = true }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const { t } = useTranslation();
  const sub = useSub();
  const [formType, setFormType] = useState<FormType>("manual");

  const { data: templatesRes } = useListContractTemplatesQuery({
    organizationId: authState.selectedOrg.id,
    orgId: authState.selectedOrg.id,
  });

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">
          {t("New contract")} {project ? `${t("connected to the project")} ${project.name}` : ""}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {templatesRes ? (
            <>
              {sub.hasAiAccess && (
                <Flex width="full" justifyContent={"center"}>
                  <Flex>
                    <Button
                      roundedRight="none"
                      variant={formType === "manual" ? "solid" : "ghost"}
                      colorScheme={"teal"}
                      rounded={"md"}
                      border="1px solid teal"
                      onClick={() => setFormType("manual")}
                    >
                      {t("Manual")}
                    </Button>
                    <Button
                      roundedLeft="none"
                      variant={formType === "ai" ? "solid" : "ghost"}
                      overflow={"hidden"}
                      colorScheme={"teal"}
                      rounded={"md"}
                      border="1px solid teal"
                      borderLeft={"none"}
                      rightIcon={<Logo />}
                      onClick={() => setFormType("ai")}
                    >
                      {t("AI")}
                    </Button>
                  </Flex>
                </Flex>
              )}
              {formType === "manual" && (
                <CreateContractForm
                  onClose={onClose}
                  project={project}
                  templates={templatesRes.templates}
                  goToContractOnCreation={goToContractOnCreation}
                />
              )}
              {formType === "ai" && <CreateContractWithAIForm project={project} templates={templatesRes.templates} />}
            </>
          ) : (
            <Flex flexDirection="column" align="center" width="full" p="2.5">
              <Skeleton
                width="40%"
                height="12"
                rounded="md"
                startColor="smBackgroundTertiary"
                endColor="smBackgroundSecondary"
              />
              <Box width="full">
                <Skeleton
                  width="25%"
                  height="6"
                  rounded="md"
                  startColor="smBackgroundTertiary"
                  endColor="smBackgroundSecondary"
                  mt="4"
                />
                <Skeleton
                  width="full"
                  height="10"
                  rounded="md"
                  startColor="smBackgroundTertiary"
                  endColor="smBackgroundSecondary"
                  mt="2"
                />
                <Skeleton
                  width="50%"
                  height="4"
                  rounded="md"
                  startColor="smBackgroundTertiary"
                  endColor="smBackgroundSecondary"
                  mt="2"
                />
              </Box>
              <Box width="full" pt="4">
                <Skeleton
                  width="25%"
                  height="6"
                  rounded="md"
                  startColor="smBackgroundTertiary"
                  endColor="smBackgroundSecondary"
                  mt="4"
                />
                <Skeleton
                  width="full"
                  height="10"
                  rounded="md"
                  startColor="smBackgroundTertiary"
                  endColor="smBackgroundSecondary"
                  mt="2"
                />
                <Skeleton
                  width="50%"
                  height="4"
                  rounded="md"
                  startColor="smBackgroundTertiary"
                  endColor="smBackgroundSecondary"
                  mt="2"
                />
              </Box>
              <Flex width="full" justify="end">
                <Skeleton
                  width="30%"
                  height="10"
                  rounded="md"
                  startColor="smBackgroundTertiary"
                  endColor="smBackgroundSecondary"
                />
              </Flex>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
