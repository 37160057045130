import { Button, Icon, Td, Text, Tr } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import { SignedDocumentDto } from "../../../../../autogen/bff-api";
import { DownloadDocumentIconButton } from "../../../../../common/documents/DownloadDocumentIconButton/DownloadDocumentIconButton";
import { SignedDocumentStatusTag } from "./SignedDocumentStatusTag";

interface Props {
  document: SignedDocumentDto;
  name: string;
  setSignedDocumentToShow: Dispatch<SetStateAction<SignedDocumentDto | undefined>>;
}

export const SignedDocumentRow = ({ document, name, setSignedDocumentToShow }: Props) => {
  const { t } = useTranslation();
  return (
    <Tr>
      <Td>
        <Text fontSize={"sm"}>{name}</Text>
      </Td>
      <Td>
        <SignedDocumentStatusTag status={document.status} />
      </Td>
      <Td>
        <Button
          leftIcon={<Icon as={FaEye} w="15px" h="15px" />}
          size="xs"
          variant={"solid"}
          colorScheme="blue"
          onClick={() => setSignedDocumentToShow(document)}
        >
          {t("View signers")}
        </Button>
      </Td>
      <Td mr="10px">
        {document.status === "Signed" && (
          <DownloadDocumentIconButton documentId={document.id} isSignedDocument={true} />
        )}
      </Td>
    </Tr>
  );
};
