import { Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { TextDocumentDto } from "../../../autogen/bff-api";
import { useSkeletons } from "../../useSkeletons";
import { TextDocumentTableRow } from "./TextDocumentTableRow";

export const TextDocumentTable = ({
  documents,
  onClick,
  isDownloadable = false,
  onDelete,
  maxHeight = "96",
  isLoading,
  noDocumentsText,
}: {
  documents?: TextDocumentDto[];
  onClick: (doc: TextDocumentDto) => void;
  isDownloadable?: boolean;
  onDelete?: (id: string) => void | Promise<void>;
  maxHeight?: string;
  isLoading: boolean;
  noDocumentsText?: string;
}) => {
  const skeletons = useSkeletons();

  return (
    <Flex
      flexDirection="column"
      width="full"
      maxHeight={maxHeight}
      overflowY="scroll"
      border="1px solid"
      borderColor="smBorder"
      rounded="md"
      pb="2.5"
    >
      <Flex p="2">{isLoading && skeletons.stackedLines(0, 3)}</Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>{t("Name")}</Th>
            <Th>{t("Last updated")}</Th>
            <Th>{t("Tags")}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {documents?.map((doc) => (
            <TextDocumentTableRow
              key={doc.id}
              doc={doc}
              onClick={onClick}
              onDelete={onDelete}
              isDownloadable={isDownloadable}
            />
          ))}
          {documents?.length === 0 && (
            <Tr>
              <Td width="full" justifyContent="center" colSpan={4} textAlign="center" fontSize="sm">
                {noDocumentsText ?? t("No text documents")}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Flex>
  );
};
