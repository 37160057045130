import { createAsyncThunk } from "@reduxjs/toolkit";
import { bffApi, EditSourcingEventRequest, EmailInviteLanguage, UploadDto } from "../../../../autogen/bff-api";
import { isBse, isBseDraft, isPublishedBse } from "../../../../pages/sourcing-events/typeguards";
import { getEmailSecretOverride } from "../../../local-storage/email-secret-override";
import { BasicSourcingEventState } from "../../reducers/basicSourcingEventReducer";
import { RootState } from "../../store";

export const editBseThunk = createAsyncThunk(
  "bse/edit",
  async (
    props: {
      command: EditBasicSourcingEventCommand;
    },
    { dispatch, getState }
  ): Promise<BasicSourcingEventState | null> => {
    const currentState = getState() as RootState;

    const currentEventState = currentState.basicSourcingEvent.state;
    if (!currentEventState) throw Error("Sourcing event state not defined");

    const response = dispatch(
      bffApi.endpoints.updateBasicSourcingEvent.initiate({
        eventId: currentEventState.id,
        editSourcingEventRequest: getEditRequest(props.command),
      })
    );
    response.reset();
    const result = await response;

    if ("data" in result && result.data) {
      const body = result.data;

      if (!isBse(body)) {
        throw Error("Invalid sourcing event state");
      }

      let description: string | null;
      let deadline: string | null;
      let uploads: UploadDto[];

      if (isBseDraft(body)) {
        description = body.draftFields.description ?? null;
        deadline = body.draftFields.deadline ?? null;
        uploads = body.draftFields.uploads;
      } else if (isPublishedBse(body)) {
        description = body.publishedFields.description;
        deadline = body.publishedFields.deadline;
        uploads = body.publishedFields.uploads;
      } else throw Error("Invalid sourcing event state - neither draft nor published.");

      return {
        id: currentEventState.id,
        event: result.data,
        lastChanged: new Date().toISOString(),
        title: {
          value: body.title,
          errorMessage: currentEventState.title.errorMessage,
        },
        description: {
          value: description,
          errorMessage: currentEventState.description.errorMessage,
        },
        deadline: {
          value: deadline,
          errorMessage: currentEventState.deadline.errorMessage,
        },
        timezone: {
          value: body.timezone,
          errorMessage: currentEventState.timezone.errorMessage,
        },
        referenceId: {
          value: body.referenceId ?? null,
          errorMessage: currentEventState.referenceId.errorMessage,
        },
        documents: {
          value: body.documents,
          errorMessage: currentEventState.documents.errorMessage,
        },
        emailInvites: {
          value: body.emailInvites,
          errorMessage: currentEventState.emailInvites.errorMessage,
        },
        organizationInvites: {
          value: body.organizationInvites,
          errorMessage: currentEventState.organizationInvites.errorMessage,
        },
        uploads: {
          value: uploads,
          errorMessage: currentEventState.uploads.errorMessage,
        },
        projects: {
          values: body.projects,
          errorMessage: currentEventState.projects.errorMessage,
        },
      };
    } else {
      switch (props.command.type) {
        case "EditTitle":
          return {
            ...currentEventState,
            title: {
              value: currentEventState.title.value,
              errorMessage: "Invalid title update.",
            },
          };
        case "EditDescription":
          return {
            ...currentEventState,
            description: {
              value: currentEventState.description.value,
              errorMessage: "Invalid description update.",
            },
          };
        case "EditDeadline":
          return {
            ...currentEventState,
            deadline: {
              value: currentEventState.deadline.value,
              errorMessage: "Invalid deadline update.",
            },
          };
        case "EditTimezone":
          return {
            ...currentEventState,
            timezone: {
              value: currentEventState.timezone.value,
              errorMessage: "Invalid timezone update.",
            },
          };
        case "AddInvitedOrganizationId":
          return {
            ...currentEventState,
            organizationInvites: {
              value: currentEventState.organizationInvites.value,
              errorMessage: "Invalid update for organization invites.",
            },
          };
        case "RemoveOrganizationInvite":
          return {
            ...currentEventState,
            organizationInvites: {
              value: currentEventState.organizationInvites.value,
              errorMessage: "Invalid update for organization invites.",
            },
          };
        case "AddEmailInvite":
          return {
            ...currentEventState,
            emailInvites: {
              value: currentEventState.emailInvites.value,
              errorMessage: "Invalid update for email invites.",
            },
          };
        case "RemoveEmailInvite":
          return {
            ...currentEventState,
            emailInvites: {
              value: currentEventState.emailInvites.value,
              errorMessage: "Invalid update for email invites.",
            },
          };
        case "EditReferenceId":
          return {
            ...currentEventState,
            referenceId: {
              value: currentEventState.referenceId.value,
              errorMessage: "Invalid update for internal reference.",
            },
          };
        case "SetProjects":
          return {
            ...currentEventState,
          };
      }
    }
  }
);

const getEditRequest = (command: EditBasicSourcingEventCommand): EditSourcingEventRequest => {
  switch (command.type) {
    case "EditTitle":
      return {
        title: command.value,
      };
    case "EditDescription":
      return {
        description: {
          value: command.value ?? undefined,
        },
      };
    case "EditDeadline":
      return {
        deadline: {
          value: command.value ?? undefined,
        },
      };
    case "EditTimezone":
      return {
        timezone: {
          value: command.value ?? undefined,
        },
      };
    case "AddInvitedOrganizationId":
      return {
        orgIdsToInvite: command.value,
      };
    case "RemoveOrganizationInvite":
      return {
        organizationInviteIdToRemove: command.value,
      };
    case "AddEmailInvite":
      return {
        emailInviteToAdd: {
          email: command.email,
          language: command.language,
        },
        emailInviteSecretOverride: getEmailSecretOverride() ?? undefined,
      };
    case "RemoveEmailInvite":
      return {
        emailInviteIdToRemove: command.value,
        emailInviteSecretOverride: getEmailSecretOverride() ?? undefined,
      };
    case "EditReferenceId":
      return {
        editReferenceId: {
          value: command.value ?? undefined,
        },
      };
    case "SetProjects":
      return {
        editProjects: command.values,
      };
  }
};

type EditTitleCommand = {
  type: "EditTitle";
  value: string;
};

type EditDescriptionCommand = {
  type: "EditDescription";
  value: string | null;
};

type EditDeadlineCommand = {
  type: "EditDeadline";
  value: string | null;
};

type EditTimezoneCommand = {
  type: "EditTimezone";
  value: string;
};

type AddInvitedOrganizationIdCommand = {
  type: "AddInvitedOrganizationId";
  value: string[];
};

type RemoveOrganizationInviteCommand = {
  type: "RemoveOrganizationInvite";
  value: string;
};

type AddEmailInviteCommand = {
  type: "AddEmailInvite";
  email: string;
  language: EmailInviteLanguage;
};

type RemoveEmailInviteCommand = {
  type: "RemoveEmailInvite";
  value: string;
};

type EditReferenceIdCommand = {
  type: "EditReferenceId";
  value: string | null;
};

type SetProjects = {
  type: "SetProjects";
  values: string[];
};

export type EditBasicSourcingEventCommand =
  | EditTitleCommand
  | EditDescriptionCommand
  | EditDeadlineCommand
  | EditTimezoneCommand
  | AddInvitedOrganizationIdCommand
  | RemoveOrganizationInviteCommand
  | AddEmailInviteCommand
  | RemoveEmailInviteCommand
  | EditReferenceIdCommand
  | SetProjects;
