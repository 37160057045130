import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TextDocumentDto, ViewDocumentAsOwnerDto } from "../../../../../autogen/bff-api";
import { SelectorValue } from "../../../../../common/input/Selector/SelectorValue";
import { CreateSignedDocument } from "./CreateSignedDocument";
import { SignerInput } from "./InvitedSigner";

interface Props {
  contractId: string;
  documents: ViewDocumentAsOwnerDto[];
  textDocuments?: TextDocumentDto[];
  sendForSigning: (values: {
    contractDocument: SelectorValue;
    attachments?: SelectorValue[];
    signers?: SignerInput[];
    deadline?: string;
  }) => Promise<boolean>;
  onClose: () => void;
}

export const SendForSigningModal = ({ contractId, documents, textDocuments, sendForSigning, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Send document for signing")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="6">
          <CreateSignedDocument
            contractId={contractId}
            documents={documents}
            textDocuments={textDocuments}
            sendForSigning={sendForSigning}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
