import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { ParentProjectDto, ProjectDto, useGetProjectsQuery } from "../../autogen/bff-api";
import { SingleSelector } from "../../common/input/Selector/SingleSelector";

export const ProjectSelector = ({
  selectedProject,
  onChange,
  onBlur,
  autoFocus = false,
}: {
  selectedProject?: ProjectDto | ParentProjectDto;
  onChange: (project: ProjectDto) => void | Promise<void>;
  onBlur?: () => void;
  autoFocus?: boolean;
}) => {
  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName, setDebouncedSearchName] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchName = useCallback(
    debounce((name: string) => setDebouncedSearchName(name), 300),
    []
  );

  const { data, isFetching: isLoadingProjects } = useGetProjectsQuery({
    name: debouncedSearchName ? debouncedSearchName : undefined,
    limit: 10,
  });

  return (
    <SingleSelector
      size="sm"
      placeholder={{ text: `${t("Select project")}...`, color: "" }}
      value={
        selectedProject
          ? {
              label: `${selectedProject.externalId ? `${selectedProject.externalId} - ` : ""}${selectedProject.name}`,
              value: selectedProject.id,
            }
          : null
      }
      inputValue={searchName}
      isLoading={isLoadingProjects}
      onInputChange={(name: string) => {
        setSearchName(name);
        handleDebouncedSearchName(name);
      }}
      options={
        data?.projects
          .filter((o) => selectedProject?.id !== o.id)
          .map((o) => ({ label: `${o.externalId ? `${o.externalId} - ` : ""}${o.name}`, value: o.id })) ?? []
      }
      noMatchingOptionsMessage={t("No projects available") ?? ""}
      onChange={async (o) => {
        const project = data?.projects.find((p) => p.id === o?.value);
        if (!project) throw Error("Project not found");
        await onChange(project);
      }}
      onBlur={onBlur}
      autoFocus={autoFocus}
    />
  );
};
