import { Flex, GridItem, Heading, Icon, Input, Spinner, Tag, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { FaClock, FaFlagCheckered } from "react-icons/fa";
import { ProjectDto, useUpdateProjectMutation } from "../../../autogen/bff-api";
import { displayDate } from "../../../common/formatting/displayDate";
import { displayDateWithTime } from "../../../common/formatting/displayDateWithTime";
import { TimestampSelector } from "../../../common/input/TimestampSelector/TimestampSelector";
import { displayPersonName } from "../../contracts/view-single/sharing/AddParticipantModal";
import { ProfileImageRounded } from "../../sourcing-events/buyer/view-all/ProfileImageRounded";

export const ProjectHeading = ({ project, refetch }: { project: ProjectDto; refetch: () => void }) => {
  const [updateProject, { isLoading }] = useUpdateProjectMutation();

  const [editName, setEditName] = useState(false);
  const [name, setName] = useState<string>();

  const [editExternalId, setEditExternalId] = useState(false);
  const [externalId, setExternalId] = useState<string>();

  const [editStartDate, setEditStartDate] = useState(false);
  const [startDate, setStartDate] = useState<string>();

  const [editEndDate, setEditEndDate] = useState(false);
  const [endDate, setEndDate] = useState<string>();

  useEffect(() => {
    setEditName(false);
    setName(undefined);
    setEditExternalId(false);
    setExternalId(undefined);
    setEditStartDate(false);
    setStartDate(undefined);
    setEditEndDate(false);
    setEndDate(undefined);
  }, [project.id]);

  useEffect(() => {
    if (name === undefined) setName(project.name);
  }, [name, project.name]);

  useEffect(() => {
    if (externalId === undefined) setExternalId(project.externalId);
  }, [externalId, project.externalId]);

  useEffect(() => {
    if (startDate === undefined) setStartDate(project.startDate);
  }, [project.startDate, startDate]);

  useEffect(() => {
    if (endDate === undefined) setEndDate(project.endDate);
  }, [project.endDate, endDate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateProject = useCallback(
    debounce(
      async ({
        name,
        externalId,
        startDate,
        endDate,
      }: {
        name?: string;
        externalId?: string;
        startDate?: string;
        endDate?: string;
      }) => {
        await updateProject({
          updateProjectRequest: {
            id: project.id,
            name,
            externalId: externalId !== undefined ? { value: externalId } : undefined,
            startDate: startDate !== undefined ? { value: startDate ? startDate : undefined } : undefined,
            endDate: endDate !== undefined ? { value: endDate ? endDate : undefined } : undefined,
          },
        });
        refetch();
      },
      200
    ),
    [project.id, updateProject]
  );

  const handleUpdateProject = useCallback(
    ({
      name,
      externalId,
      startDate,
      endDate,
    }: {
      name?: string;
      externalId?: string;
      startDate?: string;
      endDate?: string;
    }) => {
      if (name !== undefined) setName(name);
      if (externalId !== undefined) setExternalId(externalId);
      if (startDate !== undefined) setStartDate(startDate);
      if (endDate !== undefined) setEndDate(endDate);
      debouncedUpdateProject({ name, externalId, startDate, endDate });
    },
    [debouncedUpdateProject]
  );

  return (
    <>
      <GridItem area="title" pt="2.5" px="2">
        {editName ? (
          <Flex>
            <Input
              value={name ?? ""}
              autoFocus
              onBlur={() => setEditName(false)}
              fontSize="xl"
              fontWeight="bold"
              maxWidth="60%"
              _focus={{ borderWidth: "1px", shadow: "none" }}
              height="8"
              px="2"
              onChange={(e) => handleUpdateProject({ name: e.target.value })}
            />
            {isLoading && <Spinner color="teal" ml="-8" mt="1" />}
          </Flex>
        ) : (
          <Heading
            as="h2"
            size="md"
            cursor={project.canEdit ? "pointer" : "auto"}
            onClick={() => {
              if (project.canEdit) setEditName(true);
            }}
            py="1"
          >
            {project.name}
          </Heading>
        )}
        {editExternalId ? (
          <Flex>
            <Input
              value={externalId ?? ""}
              autoFocus
              onBlur={() => setEditExternalId(false)}
              maxWidth="20%"
              _focus={{ borderWidth: "1px", shadow: "none" }}
              fontSize="sm"
              height="5"
              px="1"
              m="0"
              onChange={(e) => handleUpdateProject({ externalId: e.target.value })}
            />
            {isLoading && <Spinner color="teal" ml="-5" mt="0.5" size="sm" />}
          </Flex>
        ) : (
          <Text
            color="smMuted"
            fontSize="sm"
            cursor={project.canEdit ? "pointer" : "auto"}
            onClick={() => {
              if (project.canEdit) setEditExternalId(true);
            }}
          >
            {externalId ? externalId : t("ID not provided")}
          </Text>
        )}
      </GridItem>
      <GridItem area="edit" pt="4" textAlign="end" px="2">
        <Tag colorScheme="teal">
          {t("Updated at")} {displayDateWithTime(project.modifiedAt, "")}
        </Tag>
      </GridItem>
      <GridItem area={"createdMeta"} pt="6" px="2">
        {project.projectResponsible && (
          <Flex>
            <Flex>
              <ProfileImageRounded
                name={project.projectResponsible?.firstName ?? project.name}
                width="5"
                backgroundColor="smPrimary"
                fontSize="xx-small"
              />
            </Flex>
            {project.projectResponsible && (
              <Flex justifyContent={"center"} mt="-3px" ml="5px">
                <Text fontSize={"lg"}>{displayPersonName(project.projectResponsible)}</Text>
              </Flex>
            )}
          </Flex>
        )}
      </GridItem>
      <GridItem
        area={"deadline"}
        borderBottom="1px solid"
        borderColor="smBorder"
        display="flex"
        flexDirection="column"
        pb="5"
        pt="1"
        px="2"
      >
        <Flex alignItems="center" pt="5">
          <Flex>
            <Icon as={FaClock} color="smPrimary" w="14px" h="14px" />
          </Flex>
          <Flex pl="1">
            {editStartDate ? (
              <TimestampSelector
                autoFocus
                fontSize="sm"
                datePlaceholderText={t("Select start date")}
                showTimeInput={false}
                date={startDate}
                onDateChange={(d) => {
                  handleUpdateProject({ startDate: d });
                }}
                onSelect={(d) => {
                  handleUpdateProject({ startDate: d });
                  setEditStartDate(false);
                }}
                errorMessage={null}
                dateFormat="dd MMM yyyy"
                onBlur={() => setEditStartDate(false)}
                allowPastDates={true}
              />
            ) : (
              <Text
                fontSize="sm"
                color="smPrimary"
                cursor={project.canEdit ? "pointer" : "auto"}
                onClick={() => {
                  if (project.canEdit) setEditStartDate(true);
                }}
              >
                {t("Start date")}: {startDate ? displayDate(startDate) : t("None")}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex alignItems="center" pt="2">
          <Flex>
            <Icon as={FaFlagCheckered} color="smSecondary" w="14px" h="14px" />
          </Flex>
          <Flex pl="1">
            {editEndDate ? (
              <TimestampSelector
                autoFocus
                fontSize="sm"
                datePlaceholderText={t("Select end date")}
                showTimeInput={false}
                date={endDate}
                onDateChange={(d) => {
                  handleUpdateProject({ endDate: d });
                }}
                onSelect={(d) => {
                  handleUpdateProject({ endDate: d });
                  setEditEndDate(false);
                }}
                errorMessage={null}
                dateFormat="dd MMM yyyy"
                onBlur={() => setEditEndDate(false)}
                allowPastDates={true}
              />
            ) : (
              <Text
                fontSize="sm"
                color="smSecondary"
                cursor={project.canEdit ? "pointer" : "auto"}
                onClick={() => {
                  if (project.canEdit) setEditEndDate(true);
                }}
              >
                {t("End date")}: {endDate ? displayDate(endDate) : t("None")}
              </Text>
            )}
          </Flex>
        </Flex>
      </GridItem>
    </>
  );
};
