import { Flex, Select } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./TimestampSelector.module.css";

export interface TimestampSelectorProps {
  datePlaceholderText: string;
  timezone?: {
    placeholderText: string;
    value: string;
    onChange: (value: string) => Promise<void>;
  };
  showTimeInput: boolean;
  date?: string | null;
  errorMessage: string | null;
  dateFormat: string;
  onDateChange: (date: string) => Promise<void> | void;
  isDisabled?: boolean;
  allowPastDates?: boolean;
  fontSize?: string;
  showMonthYearPicker?: boolean;
  useISO?: boolean;
  onBlur?: () => void;
  onSelect?: (date: string) => void | Promise<void>;
  autoFocus?: boolean;
}

export const TimestampSelector = ({
  datePlaceholderText,
  date,
  timezone,
  errorMessage,
  onDateChange,
  dateFormat,
  showTimeInput,
  isDisabled,
  allowPastDates,
  fontSize,
  showMonthYearPicker,
  useISO = false,
  onBlur,
  onSelect,
  autoFocus = false,
}: TimestampSelectorProps) => {
  const [selectedDate, setSelectedDate] = useState<moment.Moment>();
  const [selectedTimezone, setSelectedTimezone] = useState<string>("");
  const timezones = moment.tz.names();
  useEffect(() => {
    setSelectedDate(date ? moment(date) : undefined);
    if (timezone) setSelectedTimezone(timezone.value);
  }, [date]);

  const dateEventHandler = async (value?: Date) => {
    if (value) {
      const date = useISO ? moment(value).toISOString() : moment(value).format("YYYY-MM-DD");
      await onDateChange(date);
    } else await onDateChange("");
  };

  const dateSelectEventHandler = async (value?: Date) => {
    if (!onSelect) return;
    if (value) {
      const date = useISO ? moment(value).toISOString() : moment(value).format("YYYY-MM-DD");
      await onSelect(date);
    } else await onSelect("");
  };

  const timezoneEventHandler = async (value: string) => {
    if (timezone) {
      await timezone.onChange(value);
    }
  };

  return (
    <Flex flexDirection={"row"} w="100%" fontSize={fontSize}>
      <DatePicker
        id="date-picker"
        autoComplete="off"
        disabled={isDisabled ?? false}
        wrapperClassName={errorMessage !== null ? styles.datePickerError : styles.datePickerBase}
        showTimeInput={showTimeInput}
        selected={selectedDate?.toDate()}
        placeholderText={datePlaceholderText}
        dateFormat={dateFormat}
        showMonthYearPicker={showMonthYearPicker}
        onBlur={onBlur}
        autoFocus={autoFocus}
        filterDate={(date) => {
          if (allowPastDates) return true;
          else return moment(date).diff(moment(), "days") >= 0;
        }}
        onChange={(newValue) => {
          const modifiedNewValue = newValue ? moment(newValue) : undefined;
          if (!selectedDate && modifiedNewValue) {
            modifiedNewValue.endOf("day").seconds(59);
          }
          setSelectedDate(modifiedNewValue);
          dateEventHandler(modifiedNewValue?.toDate());
        }}
        onSelect={(newValue) => {
          const modifiedNewValue = newValue ? moment(newValue) : undefined;
          if (!selectedDate && modifiedNewValue) {
            modifiedNewValue.endOf("day").seconds(59);
          }
          setSelectedDate(modifiedNewValue);
          dateSelectEventHandler(modifiedNewValue?.toDate());
        }}
      />
      {timezone && (
        <Select
          bg="smBackground"
          placeholder={timezone.placeholderText}
          ml="3px"
          value={selectedTimezone}
          onChange={(e) => {
            setSelectedTimezone(e.target.value);
            timezoneEventHandler(e.target.value);
          }}
        >
          {timezones.map((e) => (
            <option key={e} value={e}>
              {e}
            </option>
          ))}
        </Select>
      )}
    </Flex>
  );
};
