import { Box } from "@chakra-ui/react";
import { InputActionMeta, Select, useChakraSelectProps } from "chakra-react-select";
import { SizeProp } from "chakra-react-select/dist/types/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectorOptions, SelectorValue } from "./SelectorValue";

interface Props {
  value: SelectorValue | null;
  options: SelectorOptions;
  onChange: (value: SelectorValue | null) => void;
  inputValue?: string;
  onInputChange?: ((newValue: string, actionMeta: InputActionMeta) => void) | undefined;
  placeholder?: {
    text: string;
    color: string;
  };
  noOptionsProvidedMessage?: string;
  noMatchingOptionsMessage?: string;
  isClearable?: boolean;
  size?: SizeProp;
  isLoading?: boolean;
  searchValues?: boolean;
  onBlur?: () => void;
  autoFocus?: boolean;
}

export const SingleSelector = ({
  value,
  options,
  onChange,
  inputValue,
  onInputChange,
  placeholder,
  noOptionsProvidedMessage,
  noMatchingOptionsMessage,
  isClearable,
  size,
  isLoading,
  searchValues = true,
  onBlur,
  autoFocus = false,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<SelectorValue | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const optionsMessage = noOptionsProvidedMessage ? noOptionsProvidedMessage : t("No options provided");
  const matchingOptionsMessage = noMatchingOptionsMessage ? noMatchingOptionsMessage : t("No matching elements found");

  return (
    <Select
      {...useChakraSelectProps({
        size: size ?? "md",
        isMulti: false,
        value: selectedValue,
        onChange: (option) => {
          if (option) {
            const value = {
              label: option.label,
              value: option.value,
            };
            setSelectedValue(value);
            onChange(value);
          } else {
            onChange(null);
          }
        },
        isClearable: isClearable ?? false,
        selectedOptionStyle: "check",
        placeholder: placeholder ? <Box color={placeholder.color}>{placeholder.text}</Box> : <Box></Box>,
        noOptionsMessage: (inputValue) => (
          <Box>{inputValue.inputValue.trim().length === 0 ? optionsMessage : matchingOptionsMessage}</Box>
        ),
      })}
      inputValue={inputValue}
      onInputChange={onInputChange}
      options={options}
      filterOption={customFilterOption({ disableFrontendFiltering: !!onInputChange, searchLabelsOnly: !searchValues })}
      isLoading={isLoading}
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: "smBackground",
          rounded: "md",
        }),
        container: (provided) => ({
          ...provided,
          width: "full",
        }),
        menuList: (provided) => ({
          ...provided,
          rounded: "md",
        }),
      }}
      onBlur={onBlur}
      autoFocus={autoFocus}
    />
  );
};

const customFilterOption = ({
  disableFrontendFiltering,
  searchLabelsOnly,
}: {
  disableFrontendFiltering: boolean;
  searchLabelsOnly: boolean;
}) => {
  if (disableFrontendFiltering) return () => true;
  if (searchLabelsOnly) return filterLabels;
  return undefined;
};

const filterLabels = (option: SelectorValue, inputValue: string) => {
  return option.label.toLowerCase().includes(inputValue.toLowerCase());
};
