import { Grid, GridItem, Icon, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBell, FaFolder, FaInfoCircle, FaShareAlt, FaTasks } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useGetContractQuery } from "../../../autogen/bff-api";
import { EntityViewSkeleton } from "../../../common/EntityViewSkeleton";
import { isFetchBaseQueryError } from "../../../common/errors/isFetchBaseQueryError";
import { useApiError } from "../../../common/errors/useApiError";
import { Layout } from "../../../common/layout/Layout";
import { ContractState } from "../../../common/redux/reducers/contractReducer";
import { ContractsTab, useQueryParams } from "../../../common/useQueryParams";
import { CONTENT_WIDTH } from "../../../config";
import { requireCounterparty, requireDataFields, requireStatus } from "../typeguards";
import { ContractMeta } from "./ContractMeta";
import { Details } from "./details/Details";
import { Documents } from "./documents/Documents";
import { Notifications } from "./notifications/Notifications";
import { Sharing } from "./sharing/Sharing";
import { Tasks } from "./tasks/Tasks";

export const ContractPage = () => {
  const { contractId } = useParams();
  if (!contractId) throw new Error("No contract id");
  const [tabIndex, setTabIndex] = useState(0);
  const params = useQueryParams();
  const { t } = useTranslation();
  const { data, error, refetch, isLoading } = useGetContractQuery({
    contractId,
  });
  const displayer = useApiError();

  useEffect(() => {
    const tab = params.getContractsTab();
    setTabIndex(tabToIndex(tab));
  }, [params]);

  if (error) {
    if (isFetchBaseQueryError(error) && error.status === 403) return displayer.showNoAccess();
    else return displayer.show(error);
  }

  const endDate = data ? requireDataFields(data as ContractState["contract"]).endDate : undefined;

  return (
    <Layout>
      <Grid
        height="100%"
        gap={1}
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`
                "leftContent content rightContent"
                `}
      >
        <GridItem area={"content"}>
          <Grid
            w="100%"
            gridTemplateColumns={"3fr 4fr 100px"}
            gridTemplateRows="minmax(50px,1fr) auto auto auto auto minmax(0,1fr)"
            templateAreas={`
                        "title title edit"
                        "createdMeta createdMeta edit"
                        "deadline deadline deadline"
                        "type type type"
                        "content content content"
                        `}
          >
            {isLoading && <EntityViewSkeleton />}
            {data && (
              <>
                <ContractMeta
                  id={data.id}
                  title={data.title}
                  counterparty={requireCounterparty(data)}
                  status={requireStatus(data)}
                  expirationDate={endDate?.date ?? null}
                  canEdit={data.canEditEverything}
                  template={data.template ?? null}
                />
                <GridItem area={"content"} display={"flex"} flexDirection={"column"}>
                  <Tabs
                    isLazy={true}
                    mt="25px"
                    width={"100%"}
                    index={tabIndex}
                    onChange={(index) => {
                      setTabIndex(index);
                      params.setSelectedContractsTab(indexToQueryValue(index));
                    }}
                    variant="line"
                  >
                    <TabList>
                      {data.canViewDataFields && (
                        <Tab>
                          <Icon as={FaInfoCircle} w="15px" h="15px" mr="10px" />
                          {t("Details")}
                        </Tab>
                      )}
                      {data.canViewDocuments && (
                        <Tab>
                          <Icon as={FaFolder} w="15px" h="15px" mr="10px" />
                          {t("Documents")}
                        </Tab>
                      )}
                      {data.canViewNotifications && (
                        <Tab>
                          <Icon as={FaBell} w="15px" h="15px" mr="10px" />
                          {t("Reminders")}
                        </Tab>
                      )}
                      {data.canViewTasks && (
                        <Tab>
                          <Icon as={FaTasks} w="15px" h="15px" mr="10px" />
                          {t("Tasks")}
                        </Tab>
                      )}
                      {data.canViewParticipants && (
                        <Tab>
                          <Icon as={FaShareAlt} w="15px" h="15px" mr="10px" />
                          {t("Sharing")}
                        </Tab>
                      )}
                    </TabList>
                    <TabPanels>
                      {data.canViewDataFields && (
                        <TabPanel padding="0px" paddingTop={"20px"}>
                          <Details data={data as ContractState["contract"]} />
                        </TabPanel>
                      )}
                      {data.canViewDocuments && (
                        <TabPanel padding="0px" paddingTop={"20px"}>
                          <Documents data={data} reload={refetch} />
                        </TabPanel>
                      )}
                      {data.canViewNotifications && (
                        <TabPanel padding="0px" paddingTop={"20px"}>
                          <Notifications data={data} />
                        </TabPanel>
                      )}
                      {data.canViewTasks && (
                        <TabPanel padding="0px" paddingTop={"20px"}>
                          <Tasks contract={data} />
                        </TabPanel>
                      )}
                      {data.canViewParticipants && (
                        <TabPanel padding="0px" paddingTop={"20px"}>
                          <Sharing contract={data} />
                        </TabPanel>
                      )}
                    </TabPanels>
                  </Tabs>
                </GridItem>
              </>
            )}
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};

const indexToQueryValue = (index: number): ContractsTab => {
  if (index === 0) {
    return "info";
  } else if (index === 1) {
    return "documents";
  } else if (index === 2) {
    return "reminders";
  } else if (index === 3) {
    return "tasks";
  } else if (index === 4) {
    return "sharing";
  } else {
    throw Error("should never happen");
  }
};

const tabToIndex = (tab: ContractsTab): number => {
  switch (tab) {
    case "info":
      return 0;
    case "documents":
      return 1;
    case "reminders":
      return 2;
    case "tasks":
      return 3;
    case "sharing":
      return 4;
  }
};
