import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetProjectsQuery } from "../../../autogen/bff-api";
import { displayDate } from "../../../common/formatting/displayDate";
import { Layout } from "../../../common/layout/Layout";
import { useSkeletons } from "../../../common/useSkeletons";
import { CONTENT_WIDTH } from "../../../config";
import { urls } from "../../../urls";
import { Paging } from "../../contracts/view-all-published/Paging";
import { displayPersonName } from "../../contracts/view-single/sharing/AddParticipantModal";
import { useProjectTypes } from "../useProjectTypes";

const limit = 20;

export const Projects = () => {
  const navigate = useNavigate();

  const skeletons = useSkeletons();
  const projectTypes = useProjectTypes();

  const [skip, setSkip] = useState(0);
  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName, setDebouncedSearchName] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchName = useCallback(
    debounce((name: string) => setDebouncedSearchName(name), 300),
    []
  );

  const { data, isLoading, isFetching } = useGetProjectsQuery({
    name: debouncedSearchName ? debouncedSearchName : undefined,
    limit,
    skip,
  });

  return (
    <Layout>
      <Grid
        h="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"minmax(0, 1fr)"}
        templateAreas={`"leftContent content rightContent"`}
      >
        <GridItem h="100%" area={"content"} px="2">
          <Heading as="h2" size="lg" pt="2.5" pb="4">
            {t("Projects")}
          </Heading>
          <Flex pb="2">
            <InputGroup size="sm">
              <Input
                rounded="md"
                placeholder={`${t("Search projects by ID or name")}...`}
                value={searchName}
                onChange={(e) => {
                  const name = e.target.value;
                  setSkip(0);
                  setSearchName(name);
                  handleDebouncedSearchName(name);
                }}
              />
              {isFetching && (
                <InputRightElement>
                  <Spinner size="sm" color="smPrimary" />
                </InputRightElement>
              )}
            </InputGroup>
          </Flex>
          {!isLoading && (
            <Flex flexDirection="column" border="1px solid" borderColor="smBorder" rounded="md" pt="2.5" pb="2.5">
              <Paging skip={skip} limit={limit} setSkip={setSkip} isLoading={isFetching} />
              <Box width="full" maxWidth="full" overflowX="auto">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>{t("ID")}</Th>
                      <Th>{t("Type")}</Th>
                      <Th>{t("Name")}</Th>
                      <Th>{t("Start date")}</Th>
                      <Th>{t("End date")}</Th>
                      <Th>{t("Parent project")}</Th>
                      <Th>{t("Project responsible")}</Th>
                      <Th>{t("Sourcing responsible")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.projects.map((project) => (
                      <Tr
                        key={project.id}
                        onClick={() => navigate(urls.projects.view.go(project.id))}
                        _hover={{ backgroundColor: "smBackgroundSecondary", cursor: "pointer" }}
                      >
                        <Td>{project.externalId}</Td>
                        <Td>{projectTypes.find((t) => t.value === project.projectType?.id)?.label}</Td>
                        <Td>{project.name}</Td>
                        <Td>{project.startDate ? displayDate(project.startDate) : null}</Td>
                        <Td>{project.endDate ? displayDate(project.endDate) : null}</Td>
                        <Td>{`${project.parent?.externalId ? `${project.parent.externalId} - ` : ""}${
                          project.parent?.name ?? ""
                        }`}</Td>
                        <Td>{project.projectResponsible ? displayPersonName(project.projectResponsible) : null}</Td>
                        <Td>{project.sourcingResponsible ? displayPersonName(project.sourcingResponsible) : null}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Paging skip={skip} limit={limit} setSkip={setSkip} isLoading={isFetching} />
            </Flex>
          )}
          {isLoading && <Box py="5">{skeletons.stackedLines(10, 5)}</Box>}
        </GridItem>
      </Grid>
    </Layout>
  );
};
