import {
  Button,
  Flex,
  Icon,
  LinkBox,
  LinkOverlay,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";
import { ContractListItemDto } from "../../../autogen/bff-api";
import { Explanation } from "../../../common/support/Explanation/Explanation";
import { requireStringEnvVar } from "../../../config";
import { Paging } from "./Paging";
import { PublishedContractRow } from "./PublishedContractRow";

interface Props {
  contracts: ContractListItemDto[];
  isLoading: boolean;
  skip: number;
  limit: number;
  setSkip?: Dispatch<SetStateAction<number>>;
}

export const PublishedContractsTable = ({ contracts, isLoading, skip, limit, setSkip }: Props) => {
  const { t } = useTranslation();
  const [ifrsReportingEnabled, setIfrsReportingEnabled] = useState<boolean>(false);
  const [idsSelectedForIfrsReporting, setIdsSelectedForIfrsReporting] = useState<string[]>([]);

  return (
    <Flex flexDirection={"column"} width={"full"} maxWidth={"full"} overflow={"hidden"} position={"relative"}>
      <Flex
        flexDirection={"column"}
        bg="smBackground"
        border="1px solid"
        borderColor="smBorder"
        borderRadius="lg"
        width={"full"}
        maxWidth={"full"}
        overflowX={"auto"}
      >
        {setSkip && <Paging skip={skip} limit={limit} setSkip={setSkip} isLoading={isLoading} />}
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>{t("Title")}</Th>
              <Th>{t("Counterparty")}</Th>
              <Th>{t("Internal party")}</Th>
              <Th>{t("Expiration")}</Th>
              <Th>{t("Responsible")}</Th>
              <Th>{t("Status")}</Th>
              <Th>{t("Type")}</Th>
              <Th>{t("Owner")}</Th>
              {ifrsReportingEnabled && <Th></Th>}
            </Tr>
          </Thead>
          <Tbody>
            <>
              {contracts.map((e) => {
                return (
                  <PublishedContractRow
                    key={e.id}
                    contract={e}
                    ifrsReportingEnabled={ifrsReportingEnabled}
                    selectedForIfrsReporting={idsSelectedForIfrsReporting.includes(e.id)}
                    selectedForIfrsReportingChanged={(isSelected) => {
                      if (isSelected) {
                        setIdsSelectedForIfrsReporting([...idsSelectedForIfrsReporting, e.id]);
                      } else {
                        setIdsSelectedForIfrsReporting(idsSelectedForIfrsReporting.filter((id) => id !== e.id));
                      }
                    }}
                  />
                );
              })}
              {contracts.length === 0 && (
                <Tr>
                  <Td colSpan={ifrsReportingEnabled ? 9 : 8} width="full" textAlign="center">
                    <Text p="4">{t("No contracts found")}</Text>
                  </Td>
                </Tr>
              )}
            </>
          </Tbody>
        </Table>
        {setSkip && <Paging skip={skip} limit={limit} setSkip={setSkip} isLoading={isLoading} />}
      </Flex>
      {!ifrsReportingEnabled ? (
        <Flex>
          <Spacer />
          <Button variant={"solid"} size="sm" mt="15px" onClick={() => setIfrsReportingEnabled(true)}>
            {t("IFRS reporting")}
          </Button>
        </Flex>
      ) : (
        <Flex>
          <Spacer />
          <Button variant={"solid"} size="sm" mt="15px" onClick={() => setIfrsReportingEnabled(false)}>
            {t("Cancel")}
          </Button>
          <LinkBox mt="15px" ml="5px">
            <LinkOverlay
              href={`${requireStringEnvVar(
                "VITE_BFF_BASE_URL"
              )}/api/contracts/ifrs-report?contractIds=${idsSelectedForIfrsReporting.join(",")}`}
            >
              <Explanation
                text={t("Please select at least one contract to download the IFRS report for")}
                enabled={idsSelectedForIfrsReporting.length === 0}
              >
                <Button
                  size="sm"
                  leftIcon={<Icon as={FaDownload} w="15px" h="15px" />}
                  isDisabled={idsSelectedForIfrsReporting.length === 0}
                  variant={"solid"}
                  colorScheme="blue"
                >
                  {t("Download IFRS report")}
                </Button>
              </Explanation>
            </LinkOverlay>
          </LinkBox>
        </Flex>
      )}
    </Flex>
  );
};
