import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContractListItemDto } from "../../../autogen/bff-api";
import { useSkeletons } from "../../../common/useSkeletons";
import { DraftContractRow } from "./DraftContractRow";

interface Props {
  contracts?: ContractListItemDto[];
  isLoading: boolean;
}

export const ContractDraftsTable = ({ contracts, isLoading }: Props) => {
  const { t } = useTranslation();
  const skeletons = useSkeletons();

  return (
    <Box border="1px solid" borderColor="smBorder" borderRadius="lg">
      {!contracts?.length && isLoading && skeletons.stackedLines(20, 5)}
      {contracts && (
        <Table variant="simple" size="md">
          <Thead>
            <Tr>
              <Th>{t("Title")}</Th>
              <Th>{t("Counterparty")}</Th>
              <Th>{t("Expiration")}</Th>
              <Th>{t("Value")}</Th>
              <Th>{t("Responsible")}</Th>
              <Th>{t("Status")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            <>
              {contracts.map((e) => {
                return <DraftContractRow key={e.id} contract={e} />;
              })}
              {contracts.length === 0 && (
                <Tr>
                  <Td colSpan={7} width={"100%"} textAlign="center">
                    {t("No contracts found")}
                  </Td>
                </Tr>
              )}
            </>
          </Tbody>
        </Table>
      )}
    </Box>
  );
};
