import { Box, Button, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import {
  ProjectDto,
  useGetProductGroupsForProjectQuery,
  useListEventsForBuyerQuery,
} from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { CriticalError } from "../../../../common/errors/CriticalError/CriticalError";
import { ProductFileUploader } from "../../../../common/products/ProductFileUploader";
import { ProductGroupForm, SelectedProduct } from "../../../../common/products/ProductGroupForm";
import { ProductTemplateModal } from "../../../../common/products/ProductTemplateModal";
import { useAppDispatch, useAppSelector } from "../../../../common/redux/hooks";
import { mapDtoToProductGroup, setProductGroups } from "../../../../common/redux/reducers/productReducer";
import { Product, ProductGroup } from "../../../../common/types";
import { useSkeletons } from "../../../../common/useSkeletons";
import { SourcingEventTarget } from "./SourcingEventTarget";

export const Products = ({ project }: { project: ProjectDto }) => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();
  const skeletons = useSkeletons();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productGroupDraft, setProductGroupDraft] = useState<ProductGroup>();
  const [selectedProduct, setSelectedProduct] = useState<SelectedProduct>();

  const {
    data: eventsData,
    error: eventsError,
    isLoading: isLoadingEvents,
  } = useListEventsForBuyerQuery({
    orgId: authState.selectedOrg.id,
    status: "Drafts",
    projectId: project.id,
  });

  const {
    data: productData,
    isLoading: isLoadingProducts,
    error: productError,
  } = useGetProductGroupsForProjectQuery({ projectId: project.id });

  const productGroups = useAppSelector((state) => state.product.data.productGroups);

  useEffect(() => {
    if (
      productGroups[0]?.projectId !== project.id &&
      productData?.productGroups.length &&
      productData.productGroups[0]?.projectId === project.id
    ) {
      dispatch(setProductGroups(productData?.productGroups.map((group) => mapDtoToProductGroup(group))));
    } else if (productGroups.length && productGroups[0].projectId !== project.id) {
      dispatch(setProductGroups([]));
    }
  }, [dispatch, productData?.productGroups, productGroups, project.id]);

  return (
    <Box width="full" maxWidth="full" maxHeight="full">
      {project.canEdit && (
        <>
          <ProductTemplateModal
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            productGroupDraft={productGroupDraft}
            projectId={project.id}
          />
          <ProductFileUploader
            showExplanation={productData?.productGroups.length === 0}
            setProductGroupDraft={setProductGroupDraft}
            setIsModalOpen={setIsModalOpen}
            projectId={project.id}
          />
          <Button variant={"outline"} mr="5px" colorScheme={"teal"} size={"sm"} onClick={() => setIsModalOpen(true)}>
            {t("New product group")} <Icon as={FaPlus} ml="2" />
          </Button>
        </>
      )}
      <Flex maxWidth="full" overflow="hidden" columnGap={5} maxHeight="80%">
        <Flex flexDirection="column" width="66%">
          <Heading fontSize="lg" pt="8" pb="4">
            {t("Project products")}
          </Heading>
          <Box maxHeight="80vh" overflowY="auto" shadow="inner" rounded="lg" pt="4" px="4">
            {productGroups.map((group) => (
              <Box key={group.id} pb="5">
                <ProductGroupForm
                  group={group}
                  selectedProduct={selectedProduct}
                  onSelect={(product: Product) => setSelectedProduct({ group, product })}
                  onUnSelect={() => setSelectedProduct(undefined)}
                  onProductDrag={(product: Product) => setSelectedProduct({ group, product })}
                  onProductDragEnd={() => setSelectedProduct(undefined)}
                />
              </Box>
            ))}
            {productData && !productData.productGroups.length && (
              <Text color="smMuted" pb="4">
                {t("No products yet")}...
              </Text>
            )}
            {isLoadingProducts && skeletons.stackedLines(0, 3)}
            {!productData && !isLoadingProducts && productError && <CriticalError />}
          </Box>
        </Flex>
        <Flex flexDirection="column" width="34%" pt="8">
          <Heading fontSize="lg" pb="4">
            {t("Sourcing Events")}
          </Heading>
          {eventsData?.events.map((event) => (
            <Box key={event.id} pb="2">
              <SourcingEventTarget
                event={event}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            </Box>
          ))}
          {isLoadingEvents && skeletons.stackedLines(0, 3)}
          {!eventsData && !isLoadingEvents && eventsError && <CriticalError />}
        </Flex>
      </Flex>
    </Box>
  );
};
