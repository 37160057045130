import { Box, Button, Flex, Heading, Icon } from "@chakra-ui/react";
import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { FaPlus } from "react-icons/fa";
import {
  ContractListItemDto,
  ProjectDto,
  useListContractsQuery,
  useUpdateContractMutation,
} from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { SingleSelector } from "../../../common/input/Selector/SingleSelector";
import { CreateContractModal } from "../../contracts/create/CreateContractModal";
import { ContractDraftsTable } from "../../contracts/view-all-drafts/ContractDraftsTable";

export const Contracts = ({ project }: { project: ProjectDto }) => {
  const authState = useLoggedInWithOrgContextState();

  const [updateContract, { isLoading: isUpdatingContract }] = useUpdateContractMutation();

  const { data: projectContracts, isLoading: isLoadingProjectContracts } = useListContractsQuery({
    orgId: authState.selectedOrg.id,
    projects: project.id,
  });

  const [showModal, setShowModal] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName, setDebouncedSearchName] = useState("");
  const [selectedContract, setSelectedContract] = useState<ContractListItemDto>();

  const { data: searchedContracts, isFetching: isLoadingSearchedContracts } = useListContractsQuery({
    orgId: authState.selectedOrg.id,
    title: debouncedSearchName ? debouncedSearchName : undefined,
    limit: 10,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchName = useCallback(
    debounce((name: string) => setDebouncedSearchName(name), 300),
    []
  );

  const onSelectedContract = async (contract: ContractListItemDto) => {
    setSelectedContract(contract);
    const updatedProjects = Array.from(new Set([...(contract.projects?.map((p) => p.id) ?? []), project.id]));
    await updateContract({
      contractId: contract.id,
      updateContractRequest: {
        editProjects: updatedProjects,
      },
    });
    setSearchName("");
    setSelectedContract(undefined);
  };

  return (
    <>
      {showModal && (
        <CreateContractModal project={project} onClose={() => setShowModal(false)} goToContractOnCreation={false} />
      )}
      <Box>
        <Flex columnGap={6}>
          <Box>
            <Button
              leftIcon={<Icon as={FaPlus} />}
              variant="outline"
              colorScheme="teal"
              size="sm"
              onClick={() => setShowModal(true)}
            >
              {t("New contract")}
            </Button>
          </Box>
          <SingleSelector
            size="sm"
            isClearable
            placeholder={{ text: `${t("Connect a contract to this project")}...`, color: "" }}
            isLoading={isLoadingSearchedContracts || isUpdatingContract}
            value={selectedContract ? { label: selectedContract.title, value: selectedContract.id } : null}
            options={searchedContracts?.contracts.map((c) => ({ value: c.id, label: c.title })) ?? []}
            inputValue={searchName}
            onInputChange={(name: string) => {
              setSearchName(name);
              handleDebouncedSearchName(name);
            }}
            onChange={async (o) => {
              if (o === null) return;
              const contract = searchedContracts?.contracts.find((c) => c.id === o?.value);
              if (!contract) throw Error("Contract not found");
              await onSelectedContract(contract);
            }}
          />
        </Flex>
        <Heading fontSize="lg" py="5">
          {t("Contracts connected to this project")}
        </Heading>
        <ContractDraftsTable isLoading={isLoadingProjectContracts} contracts={projectContracts?.contracts} />
      </Box>
    </>
  );
};
