import { Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  CreateProductDto,
  ProductDto,
  ProductTemplateFieldDto,
  SeDto,
  useCreateProductGroupMutation,
  useEditProductGroupMutation,
  useGetProductGroupsForSourcingEventQuery,
} from "../../../../autogen/bff-api";
import { useRumError } from "../../../../common/errors/RumComponentContext/useRumError";
import { SelectedProduct } from "../../../../common/products/ProductGroupForm";
import { findOriginProduct, getProductCount } from "../../../../common/products/productUtils";
import { ProductGroup } from "../../../../common/types";

export const SourcingEventTarget = ({
  event,
  selectedProduct,
  setSelectedProduct,
}: {
  event: SeDto;
  selectedProduct?: SelectedProduct;
  setSelectedProduct: Dispatch<SetStateAction<SelectedProduct | undefined>>;
}) => {
  const toast = useToast();
  const rumError = useRumError();

  const [isDropping, setIsDropping] = useState(false);

  const [createProductGroup, { isLoading: isCreatingProductGroup }] = useCreateProductGroupMutation();
  const [updateProductGroup, { isLoading: isUpdatingProductGroup }] = useEditProductGroupMutation();

  const {
    data,
    isLoading,
    isFetching: isFetchingProducts,
    error,
    refetch,
  } = useGetProductGroupsForSourcingEventQuery({ sourcingEventId: event.id });

  const productCount = useMemo(() => {
    if (data) return getProductCount(data.productGroups as ProductGroup[]);
  }, [data]);

  const groupCount = useMemo(() => {
    return data?.productGroups.length;
  }, [data?.productGroups.length]);

  const matchedProduct = useMemo(() => {
    if (selectedProduct && data?.productGroups.length) {
      return findOriginProduct({
        productId: selectedProduct.product.id,
        originProductGroupId: selectedProduct.group.id,
        groups: data.productGroups as ProductGroup[],
      });
    }
  }, [data?.productGroups, selectedProduct]);

  const matchingGroup = useMemo(
    () => data?.productGroups.find((g) => g.originProductGroupId === selectedProduct?.group.id),
    [data?.productGroups, selectedProduct?.group.id]
  );

  useEffect(() => {
    if (!selectedProduct) setIsDropping(false);
  }, [selectedProduct]);

  return (
    <Flex
      key={event.id}
      flexDirection="column"
      rounded="md"
      border="2px dashed"
      borderColor={isDropping && event.canEdit ? "smSecondary" : "smBorder"}
      p="5"
      onDragOver={(e) => {
        e.preventDefault();
        setIsDropping(true);
      }}
      onDragLeave={() => {
        setIsDropping(false);
      }}
      onDrop={async () => {
        if (!event.canEdit) {
          toast({
            title: `${t("Not allowed")}.`,
            description: `${t("Only admins and creators can edit products")}...`,
            status: "warning",
            variant: "subtle",
          });
          return;
        }
        if (!selectedProduct) {
          toast({ description: t("Something went wrong - could not add product"), status: "error" });
          rumError(new Error("Failed to add project product to sourcing event"), undefined);
          return;
        }
        if (matchedProduct && matchingGroup) {
          await updateProductGroup({
            editProductGroupDto: {
              id: matchingGroup.id,
              editProduct: { ...(selectedProduct.product as ProductDto) },
            },
          });
          toast({
            title: `${t("Updated product")}!`,
            description: `${t("The product was updated in the product group")} ${matchingGroup.name}`,
            variant: "subtle",
            status: "success",
          });
        } else if (matchingGroup) {
          await updateProductGroup({
            editProductGroupDto: {
              id: matchingGroup.id,
              addProduct: { product: { ...(selectedProduct.product as ProductDto) } },
            },
          });
          toast({
            title: `${t("Added product")}!`,
            description: `${t("The product was added to the product group")} ${matchingGroup.name}`,
            variant: "subtle",
            status: "success",
          });
        } else {
          await createProductGroup({
            createProductGroupDto: {
              name: selectedProduct.group.name,
              originProductGroupId: selectedProduct.group.id,
              sourcingEventId: event.id,
              productFields: selectedProduct.group.productFields as ProductTemplateFieldDto[],
              products: [selectedProduct.product as CreateProductDto],
            },
          });
          toast({
            title: `${t("Added product")}!`,
            description: `${t("A new product group with the selected product was added to the sourcing event")} ${
              event.title
            }`,
            variant: "subtle",
            status: "success",
          });
        }
        setSelectedProduct(undefined);
        setIsDropping(false);
        refetch();
      }}
    >
      <Text fontWeight="bold" pb="4">
        {event.title}
      </Text>
      {data && !selectedProduct && (
        <Text fontSize="sm" pb="2">
          {groupCount} {groupCount === 1 ? t("product group") : t("product groups")} {t("with")} {productCount}{" "}
          {productCount === 1 ? t("product") : t("products")} {t("in total")}.
        </Text>
      )}
      {data && selectedProduct && (
        <Text fontSize="sm" color={matchedProduct ? "smPrimary" : "smSecondary"} pb="2">
          {matchedProduct
            ? `${t("Product already added")}. ${event.canEdit ? `${t("Drag and drop to overwrite")}.` : ""}`
            : `${t("Product not added yet")}.`}
        </Text>
      )}
      {(isCreatingProductGroup || isUpdatingProductGroup) && (
        <Flex fontSize="sm" align="center" pb="2">
          <Text pr="1">{t("Updating sourcing event products")}.</Text>
          <Spinner color="smPrimary" size="sm" />
        </Flex>
      )}
      {isFetchingProducts && (
        <Flex fontSize="sm" align="center" pb="2">
          <Text pr="1">{t("Loading products")}.</Text>
          <Spinner color="smPrimary" size="sm" />
        </Flex>
      )}
      {!isLoading && !data && error && <Text color="red">{t("Failed to load products")}.</Text>}
    </Flex>
  );
};
