import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { requireDataFields } from "../../../../typeguards";
import { useContractState } from "../../../useContractState";
import { GenericTextArea } from "./GenericTextArea";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const DeliveryTerms = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const dataFields = requireDataFields(contractState.contract);
  const text = dataFields.deliveryTerms ?? null;
  const errorMessage = containsError(contractState, "EditDeliveryTerms") ? t("Update failed") : null;

  return (
    <GenericTextArea
      label={t("Delivery terms")}
      placeholderText={""}
      helperText={t("Provide the delivery terms of the contract")}
      text={text}
      errorMessage={errorMessage}
      editText={async (newText) => {
        await dispatch(
          editContractThunk({
            command: {
              type: "EditDeliveryTerms",
              value: newText,
            },
          })
        );
      }}
      removeDataField={removeDataField}
      isRequiredButNotProvided={isRequiredButNotProvided}
    />
  );
};
