import { Box, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useListContractsQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { Layout } from "../../../common/layout/Layout";
import { CONTENT_WIDTH } from "../../../config";
import { ContractDraftsTable } from "./ContractDraftsTable";

export const ContractDrafts = () => {
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const displayer = useApiError();

  const { data, error, isLoading } = useListContractsQuery({
    orgId: authState.selectedOrg.id,
    state: "Draft",
  });

  if (error) {
    return displayer.show(error);
  }

  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`
                "leftContent content rightContent"
                `}
      >
        <GridItem area={"content"}>
          <Grid h="100%" gridTemplateColumns={"1fr"} gridTemplateRows={"70px 1fr"}>
            <GridItem display="flex" alignItems="center" borderBottom={"1px solid #e1e6ee"} px="2">
              <Heading as="h2" size="lg">
                {t("Contract drafts")}
              </Heading>
            </GridItem>
            <GridItem px="2">
              <Box mt="15px" pb="7">
                <Text fontSize="medium" color="smMuted">
                  {t("Contract drafts that you have access to")}
                </Text>
              </Box>
              <ContractDraftsTable isLoading={isLoading} contracts={data?.contracts} />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
