import { Button, Flex, Spinner } from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";

export const Paging = ({
  limit,
  skip,
  setSkip,
  isLoading,
}: {
  limit: number;
  skip: number;
  setSkip: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
}) => {
  return (
    <Flex alignItems={"center"} justifyContent={"center"} p="4">
      <Flex w="10">{isLoading && <Spinner color="smPrimary" size="sm" />}</Flex>
      <Flex alignItems={"center"} justifyContent={"center"} columnGap={"1"}>
        <Button
          colorScheme="teal"
          variant={"ghost"}
          size={"xs"}
          isDisabled={skip - limit < 0}
          onClick={() => setSkip(skip - limit)}
        >
          {t("Previous")}
        </Button>
        {skip / limit > 0 && (
          <Flex
            borderColor="smPrimary"
            border="1px solid"
            color="smPrimary"
            fontWeight={"bold"}
            fontSize={"xs"}
            rounded="full"
            width="6"
            height="6"
            justifyContent={"center"}
            alignItems={"center"}
            cursor="pointer"
            onClick={() => setSkip(skip - limit)}
          >
            {limit ? skip / limit : 1}
          </Flex>
        )}
        <Flex
          backgroundColor="smPrimary"
          color="smBackground"
          fontWeight={"bold"}
          fontSize={"xs"}
          rounded="full"
          width="6"
          height="6"
          justifyContent={"center"}
          alignItems={"center"}
        >
          {limit ? skip / limit + 1 : 1}
        </Flex>
        <Flex
          borderColor="smPrimary"
          border="1px solid"
          color="smPrimary"
          fontWeight={"bold"}
          fontSize={"xs"}
          rounded="full"
          width="6"
          height="6"
          justifyContent={"center"}
          alignItems={"center"}
          cursor="pointer"
          onClick={() => setSkip(skip + limit)}
        >
          {limit ? skip / limit + 2 : 1}
        </Flex>
        <Button colorScheme="teal" variant={"ghost"} size={"xs"} onClick={() => setSkip(skip + limit)}>
          {t("Next")}
        </Button>
      </Flex>
    </Flex>
  );
};
