import "react-datepicker/dist/react-datepicker.css";
import { ContractDataFieldNameDto } from "../../../../../autogen/bff-api";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { editContractThunk } from "../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { requireDataFields } from "../../../typeguards";
import { useContractState } from "../../useContractState";
import { ContractOptions } from "./Options";
import { AwardDate } from "./dates/AwardDate";
import { ExpirationDate } from "./dates/ExpirationDate";
import { NoticePeriod } from "./dates/NoticePeriod";
import { RenewalDate } from "./dates/RenewalDate";
import { StartDate } from "./dates/StartDate";
import { TerminationDate } from "./dates/TerminationDate";
import { WarrantyExpirationDate } from "./dates/WarrantyExpirationDate";
import { EstimatedValue } from "./financial/EstimatedValue";
import { IfrsValues } from "./financial/IfrsValues";
import { PricePerMonth } from "./financial/PricePerMonth";
import { PricePerYear } from "./financial/PricePerYear";
import { TotalLiability } from "./financial/TotalLiability";
import { TotalPrice } from "./financial/TotalPrice";
import { CounterpartyContactPerson } from "./other/CounterpartyContactPerson";
import { DeliveryTerms } from "./other/DeliveryTerms";
import { EditProjects } from "./other/EditProjects";
import { ExternalLink } from "./other/ExternalLink";
import { InternalParties } from "./other/InternalParties";
import { InternalReferenceNumber } from "./other/InternalReferenceNumber";
import { LinkedContacts } from "./other/LinkedContracts";
import { OriginSourcingEvent } from "./other/OriginSourcingEvent";
import { PaymentTermsInDays } from "./other/PaymentTermsInDays";
import { Priority } from "./other/Priority";
import { PurchasingPolicy } from "./other/PurchasingPolicy";
import { Risk } from "./other/Risk";
import { SupplierCategory } from "./other/SupplierCategory";
import { SupplierInfo } from "./other/SupplierInfo";

interface Props {
  name: ContractDataFieldNameDto;
}

export const DataField = ({ name }: Props) => {
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const dataFields = requireDataFields(contractState.contract);
  const isRequired = dataFields.requiredDataFields.map((e) => e.name).includes(name);
  const removeDataField = async () => {
    await dispatch(
      editContractThunk({
        command: {
          type: "RemoveDataField",
          value: name,
        },
      })
    );
  };

  switch (name) {
    case "AwardDate":
      return <AwardDate removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />;
    case "RenewalDate":
      return (
        <RenewalDate removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "CounterpartyContactPerson":
      return (
        <CounterpartyContactPerson
          removeDataField={!isRequired ? removeDataField : undefined}
          isRequiredButNotProvided={false}
        />
      );
    case "DeliveryTerms":
      return (
        <DeliveryTerms removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "EstimatedValue":
      return (
        <EstimatedValue removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "EndDate":
      return (
        <ExpirationDate removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "ExternalLink":
      return (
        <ExternalLink removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "InternalReferenceNumber":
      return (
        <InternalReferenceNumber
          removeDataField={!isRequired ? removeDataField : undefined}
          isRequiredButNotProvided={false}
        />
      );
    case "LinkedContracts":
      return (
        <LinkedContacts removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "OriginSourcingEvent":
      return (
        <OriginSourcingEvent
          removeDataField={!isRequired ? removeDataField : undefined}
          isRequiredButNotProvided={false}
        />
      );
    case "PaymentTermsInDays":
      return (
        <PaymentTermsInDays
          removeDataField={!isRequired ? removeDataField : undefined}
          isRequiredButNotProvided={false}
        />
      );
    case "PricePerMonth":
      return (
        <PricePerMonth removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "PricePerYear":
      return (
        <PricePerYear removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "Priority":
      return <Priority removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />;
    case "PurchasingPolicy":
      return (
        <PurchasingPolicy
          removeDataField={!isRequired ? removeDataField : undefined}
          isRequiredButNotProvided={false}
        />
      );
    case "Risk":
      return <Risk removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />;
    case "StartDate":
      return <StartDate removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />;
    case "TotalLiability":
      return (
        <TotalLiability removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "TotalPrice":
      return (
        <TotalPrice removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "WarrantyExpirationDate":
      return (
        <WarrantyExpirationDate
          removeDataField={!isRequired ? removeDataField : undefined}
          isRequiredButNotProvided={false}
        />
      );
    case "TerminationDate":
      return <TerminationDate removeDataField={removeDataField} isRequiredButNotProvided={false} />;
    case "IfrsValues":
      return (
        <IfrsValues removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "InternalParties":
      return (
        <InternalParties removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "SupplierInfo":
      return (
        <SupplierInfo removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "SupplierCategory":
      return (
        <SupplierCategory
          removeDataField={!isRequired ? removeDataField : undefined}
          isRequiredButNotProvided={false}
        />
      );
    case "NoticePeriod":
      return (
        <NoticePeriod removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
    case "Options":
      return <ContractOptions />;
    case "Projects":
      return (
        <EditProjects removeDataField={!isRequired ? removeDataField : undefined} isRequiredButNotProvided={false} />
      );
  }
};
