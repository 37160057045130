import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";
import { ListSourcingEventsFilterStatus, SeDto } from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { useConfirmationModal } from "../../../../common/useConfirmationModal";
import { useSkeletons } from "../../../../common/useSkeletons";
import { EventRow } from "./EventRow";

export const SourcingEventsTableForBuyer = ({
  events,
  status,
  isLoading,
  error,
}: {
  events?: SeDto[];
  status: ListSourcingEventsFilterStatus;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}) => {
  const confirmationModal = useConfirmationModal();
  const { t } = useTranslation();
  const skeletons = useSkeletons();
  const displayer = useApiError();

  if (error) return displayer.show(error);

  return (
    <>
      {confirmationModal.modal}
      <Box rounded="lg" mt="5" border="1px solid" borderColor="smBorder" maxWidth="full" overflowX="auto">
        {!events?.length && isLoading && skeletons.stackedLines(20, 5)}
        {events && (
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>{t("Event name")}</Th>
                <Th>{t("Internal reference")}</Th>
                <Th>{t("Created by")}</Th>
                <Th width="36">{t("Deadline")}</Th>
                <Th>{t("Status")}</Th>
                {status === "Drafts" || status === "Ongoing" ? <Th></Th> : <Th>{t("Winners")}</Th>}
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              <>
                {events.map((e) => (
                  <EventRow key={e.id} event={e} confirmationModal={confirmationModal} />
                ))}
                {events.length === 0 && (
                  <Tr>
                    <Td colSpan={6} width={"100%"} textAlign="center">
                      {t("No events")}.
                    </Td>
                  </Tr>
                )}
              </>
            </Tbody>
          </Table>
        )}
      </Box>
    </>
  );
};
