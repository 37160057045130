import { Button, Flex, Text, Textarea, useToast } from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ContractTaskCommentDto,
  useDeleteContractTaskCommentMutation,
  useUpdateContractTaskCommentMutation,
} from "../../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../../common/auth/useLoggedInWithOrgContextState";
import { displayDateWithTime } from "../../../../../../common/formatting/displayDateWithTime";
import { Explanation } from "../../../../../../common/support/Explanation/Explanation";
import { displayPersonName } from "../../../sharing/AddParticipantModal";
import { CommentContent } from "./CommentContent";
import { RemoveCommentButton } from "./RemoveCommentButton";

interface Props {
  comment: ContractTaskCommentDto;
  onChangeComplete: () => void;
}

export const Comment: React.FC<Props> = ({ comment, onChangeComplete }) => {
  const [showEditing, setShowEditing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(comment.text);
  const { t } = useTranslation();
  const [deleteComment] = useDeleteContractTaskCommentMutation();
  const [editComment] = useUpdateContractTaskCommentMutation();
  const toast = useToast();
  const authState = useLoggedInWithOrgContextState();

  const createdByMe = authState.me.id === comment.createdBy.id;

  return (
    <Flex flexDirection={"column"} mt="15px">
      <Flex>
        <Text fontWeight={"semibold"} fontSize="md" color={createdByMe ? "blue.500" : "#42526e"}>
          {createdByMe ? t("You") : displayPersonName(comment.createdBy)}
        </Text>
        <Explanation enabled={true} text={displayDateWithTime(comment.createdAt, t("at"))}>
          <Text fontSize="sm" color={createdByMe ? "blue.500" : "#42526e"} ml="15px" mt="2px">
            {moment(comment.createdAt).fromNow()}
          </Text>
        </Explanation>
      </Flex>
      <Flex mt="5px">
        {showEditing ? (
          <Flex flexDirection={"column"} w="100%">
            <Textarea
              w="100%"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder={t("Edit comment") ?? ""}
            />
            <Flex flexDirection={"column"}>
              <Flex mt="5px">
                <Button
                  id="save-comment-button"
                  variant={"solid"}
                  colorScheme="teal"
                  size={"sm"}
                  mr="5px"
                  isLoading={isEditing}
                  onClick={async () => {
                    if (!text) {
                      throw new Error("Should never happen");
                    }
                    setIsEditing(true);
                    const result = await editComment({
                      commentId: comment.id,
                      updateContractTaskCommentRequest: {
                        text: text,
                      },
                    });
                    setIsEditing(false);
                    if ("data" in result) {
                      onChangeComplete();
                      setShowEditing(false);
                    } else {
                      toast({
                        title: t("Could not edit comment"),
                        status: "error",
                      });
                    }
                    setIsEditing(false);
                  }}
                >
                  {t("Save")}
                </Button>
                <Button
                  variant={"solid"}
                  colorScheme="gray"
                  size={"sm"}
                  isDisabled={isEditing}
                  onClick={() => setShowEditing(false)}
                >
                  {t("Cancel")}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <CommentContent comment={comment} />
        )}
      </Flex>
      {comment.canUpdate && !comment.deletedAt && (
        <Flex mt="10px">
          <Button id="edit-comment-button" variant="link" size="sm" ml="-2px" onClick={() => setShowEditing(true)}>
            {t("Edit")}
          </Button>
          <RemoveCommentButton
            confirmRemoval={async () => {
              const result = await deleteComment({
                commentId: comment.id,
              });

              if ("data" in result) {
                onChangeComplete();
              } else {
                toast({
                  title: t("Could not delete comment"),
                  status: "error",
                });
              }
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};
